import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import ReactGA from 'react-ga4';

import {
    Elements,
    PaymentElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import './checkout.scss';
import {
    blue300,
    blue500,
    gray010,
    gray300,
    gray600,
    gray800
} from "../../uiKit/assets/colors/colors";
import { BankCard } from "../../uiKit/assets/svg/icons";
import CustomButton from "../../uiKit/Button/CustomButton";
import Title from "../../uiKit/Title/Title";

import Header from "../../components/Header/header";


import {
    campaignPaymentIntent,
    campaignPaymentIntentSaved,
    ourBalancePaymentIntent,
    ourBalancePaymentIntentSaved
} from "../../redux/state/paymentSlice";
import CheckoutInfo from "./checkoutInfo";
import Loading from "../../uiKit/Loading/loading";
import { FUND_INFO } from "../../helpers/constants";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });

const CheckoutForm = ({ newCard }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentElementReady, setIsPaymentElementReady] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const history = useHistory()
    const { pathname, search } = useLocation();
    const beMember = new URLSearchParams(search).get('beMember') || '';
    const communityName = new URLSearchParams(search).get('name') || '';

    const payWithNewCard = async () => {

        if (!stripe || !elements) {
            return;
        }

        ReactGA.event(`donate_${pathname.split('/')[1].replace('-', '_')}_new_card_checkout`);

        setIsLoading(true);
        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        })

        if (result.paymentIntent?.status === "succeeded") {
            setIsLoading(false);
            history.push(`/${pathname.split('/')[1]}/thank-you?beMember=${beMember}&name=${communityName}`)
        }

        if (result.error) {
            setIsLoading(false);
            toast.info(result.error.message);
        }

    }

    return (
        !newCard && (
            <>
                <div style={{ marginTop: '16px' }}>
                    <PaymentElement
                        options={{
                            readOnly: isLoading
                        }}
                        onReady={() => setIsPaymentElementReady(true)}
                    />
                </div>
                <CheckoutInfo />
                <CustomButton
                    title='Donate'
                    onClick={payWithNewCard}
                    style={{ marginTop: '16px' }}
                    disabled={!stripe || !isPaymentElementReady || isLoading}
                    loading={isLoading}
                />
            </>
        )
    )
}

const Checkout = () => {

    const clientSecret = useSelector(state => state.payment.paymentInfo.clientSecret, shallowEqual);
    const savedCard = useSelector(state => state.payment.savedCard, shallowEqual);

    const isLoadingOurBalanceIntent = useSelector(state => state.payment.isLoading.ourBalancePaymentIntent, shallowEqual);
    const isLoadingCampaignIntent = useSelector(state => state.payment.isLoading.campaignPaymentIntent, shallowEqual);
    const error = useSelector(state => state.payment.error, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();
    const { pathname, state } = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [useSavedCard, setUseSavedCard] = useState(false);

    useEffect(() => {
        if (!!error) {
            toast.info(error.errors.Amount[0] ?? 'something went wrong');
            history.goBack();
        }
    }, [error, history])

    useEffect(() => {
        if (!!savedCard && !!savedCard.id) {
            setUseSavedCard(true);
        }
    }, [savedCard, dispatch])

    useEffect(() => {
        if (!!savedCard && !useSavedCard && !!state) {
            const { body, balanceType } = state;
            if (!!balanceType) {
                dispatch(campaignPaymentIntent({
                    body,
                    communityId: body.incomingBalanceId,
                    campaignId: body.campaignId
                }))
            } else {
                dispatch(ourBalancePaymentIntent({
                    body,
                    communityId: body.incomingBalanceId
                }))
            }
        }
    }, [savedCard, useSavedCard, state, dispatch])



    const payWithSavedCard = () => {
        // balance type 0: our balance, 1: active campaign
        ReactGA.event(`donate_${pathname.split('/')[1].replace('-', '_')}_with_saved_card_checkout`);
        const { body, balanceType } = state;
        setIsLoading(true);
        if (!balanceType) {
            dispatch(ourBalancePaymentIntentSaved({
                communityId: body.incomingBalanceId,
                paymentMethodId: savedCard.id,
                body: body
            }))
                .then(unwrapResult)
                .then((response) => {
                    if (response.status === 'Success')
                        history.push(`/${pathname.split('/')[1]}/thank-you`)
                    else {
                        setIsLoading(false);
                        toast.info(response.errorMessage ?? 'Something went wrong.')
                    }
                })
                .catch(({ title }) => {
                    setIsLoading(false);
                    toast.info(`Payment failed: ${title}`)
                })
        } else {
            dispatch(campaignPaymentIntentSaved({
                communityId: body.incomingBalanceId,
                campaignId: body.campaignId,
                paymentMethodId: savedCard.id,
                body: body
            }))
                .then(unwrapResult)
                .then((response) => {
                    if (response.status === 'Success')
                        history.push(`/${pathname.split('/')[1]}/thank-you`)
                    else {
                        setIsLoading(false);
                        toast.info(response.errorMessage ?? 'Something went wrong.')
                    }
                })
                .catch(({ title }) => {
                    setIsLoading(false);
                    toast.info(`Payment failed: ${title}`)
                })
        }

    }

    return (
        <div style={{ flexDirection: 'column', zIndex: 1000 }}>
            <Header title='Donation Amount' onClick={() => history.goBack()} hasMenu={!pathname.includes('anonymous')} />
            <div className="formWrapper" >
                <div className="page">
                    {
                        !!savedCard && !!savedCard.id && (
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div
                                    style={{
                                        width: '174px',
                                        height: '78px',
                                        border: `1px solid ${useSavedCard ? blue300 : gray300}`,
                                        borderRadius: '10px',
                                        marginRight: '16px',
                                        padding: '16px',
                                        backgroundColor: gray010,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setUseSavedCard(true)}
                                >
                                    <BankCard disabled={useSavedCard} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Title
                                            text='Card'
                                            fontWeight={500}
                                            color={`${useSavedCard ? blue500 : gray800}`}
                                        />
                                        <Title
                                            text={savedCard.last4}
                                            fontWeight={500}
                                            color={`${useSavedCard ? blue500 : gray800}`}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '174px',
                                        height: '78px',
                                        border: `1px solid ${!useSavedCard ? blue300 : gray300}`,
                                        borderRadius: '10px',
                                        padding: '16px',
                                        backgroundColor: gray010,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setUseSavedCard(false)}
                                >
                                    <BankCard disabled={!useSavedCard} />
                                    <Title
                                        text='New card'
                                        fontWeight={500}
                                        color={`${!useSavedCard ? blue500 : gray800}`}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        !!clientSecret && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm newCard={useSavedCard} />
                            </Elements>
                        )
                    }
                    {
                        useSavedCard && (
                            <>
                                <CheckoutInfo />
                                <CustomButton
                                    title='Donate'
                                    onClick={payWithSavedCard}
                                    style={{ marginTop: '16px' }}
                                    disabled={isLoading || isLoadingCampaignIntent || isLoadingOurBalanceIntent}
                                    loading={isLoading || isLoadingCampaignIntent || isLoadingOurBalanceIntent}
                                />
                                <Title
                                    text={FUND_INFO}
                                    fontSize={12}
                                    lineHeight={14}
                                    overflowText={false}
                                    fontWeight={400}
                                    style={{
                                        marginTop: "16px",
                                    }}
                                    color={gray600}
                                />
                            </>
                        )
                    }
                    {
                        (isLoadingOurBalanceIntent || isLoadingCampaignIntent) && <Loading />
                    }
                </div>
            </div>
        </div>
    )
}

export default Checkout;