import {
    HandshakeOutlined as HandShake,
    AccessibilityNewOutlined as Dignity,
    SpeedOutlined as Speed,
    DirectionsRunOutlined as Action,
    MoneyOffOutlined as NonProfit,
    Diversity3Outlined as Respect,
} from '@mui/icons-material';

export const FUND_INFO = `"All contributions are made and received on the condition and with the understanding that Lift Our Own maintains complete control and discretion over the use and distribution of the contributed funds."`
export const GRANT_APPLICATION_PDF = 'https://ourcommunityblob.blob.core.windows.net/ourcommunity/ApplicationProcess/Grant%20Application.pdf';
export const APPLICATION_PROCESS = 'https://ourcommunityblob.blob.core.windows.net/ourcommunity/ApplicationProcess/Poverty%20Level.pdf'

export const YOUTUBE_LINK = "https://www.youtube.com/@ourcommunity4155/featured";

export const OUR_VALUES = [
    { id: 0, Icon: Respect, title: 'Respect', description: 'ourcommunity.one establishes the recipients complete anonymity.' },
    { id: 1, Icon: Dignity, title: 'Dignity', description: 'We ensure throughout the entire process that the individual in need can continue to hold their head high.' },
    { id: 2, Icon: Speed, title: 'Speed', description: 'When a community peer is struggling financially, oftentimes they need the funds immediately. Our platform creates the ability to launch a campaign and process the funds swiftly and securely, to get the money into the recipients hands.' },
    { id: 3, Icon: HandShake, title: 'Empowerment', description: 'We are a tool, a bridge between peers. By empowering local community members to make their own decisions, we believe the correct help is delivered. Only a fellow member truly understands the needs within their community.' },
    { id: 4, Icon: Action, title: 'Action first', description: 'Problems are not in short supply, but we are solutions-focused. We choose our problems carefully, and act decisively to fix them. By focusing on actionable issues exclusively, we keep ourselves forward looking. Problem, fix, next.' },
    { id: 5, Icon: NonProfit, title: 'We are a true non for profit', description: 'All the costs to run the organization are raised from outside donors exclusively.' },
]

export const FAQS = [
    {
        id: '1',
        expanded: false,
        question: 'Why was ourcommunity created?',
        answer: "We believe that there is a crucial category of need that tends to fall through the cracks. There are those very close to us who may be experiencing personal financial difficulties, and the person has no place to turn to while we are simply unaware. We aim to bridge that gap."
    },
    {
        id: '2',
        expanded: false,
        question: 'How does ourcommunity make money?',
        answer: "We don't! We are a non for profit and we cover our entire budget from outside contributions. Every single dollar (minus the credit card processing fees, which have non for profit rates, the best in the industry) raised on this platform goes directly to those in need."
    },
    {
        id: '3',
        expanded: false,
        question: 'Does ourcommunity run campaigns directly? ',
        answer: "No. We provide the platform and the tools to enable each group to easily create, manage and raise for for their own members. We also process and distribute the funds for all groups."
    },
    {
        id: '4',
        expanded: false,
        question: 'Does ourcommunity have a donor database?',
        answer: "No. We believe in a decentralized giving model. Only a fellow community member will truly know their fellow members situation and can help out accordingly. We act as the bridge for this peer to peer giving model, but the actual approval and fundrasing is done on a group-by-group level."
    },
    {
        id: '5',
        expanded: false,
        question: "Who do I reach out to if I'm having financial difficulty?",
        answer: "Each community has 1-3 admins. They are the trusted and discreet members which every group typically has. They will know their fellow members situation and will be able to facilitate the entire process. "
    }, {
        id: '6',
        expanded: false,
        question: "My community doesn't yet have a group on ourcommunity, what should I do?",
        answer: "Reach out to your trusted group member who would be the admin and ask them to create a page for your community! Remember, a community is not limited to a physical location. It can be a class alumni group, former camp connections or any other community you may be a part of. "
    },
    {
        id: '7',
        expanded: false,
        question: 'Is it difficult for the admin to manage campaigns?',
        answer: "No, it is extremelly easy! We put a lot of thought into the admins user experience. This platform will enable you to do more good for your community! It will help you facilitate giving to one another, and it will allow you to free up time to focus on other, much needed initiatives for your community. You are still needed every step of the way, but the process of connecting members will be infinitely easier and more efficient. "
    },
    {
        id: '8',
        expanded: false,
        question: "I'm a community leader and we already raise funds for those in need. How will this help?",
        answer: "The current process for most is still quite laborious and time consuming. Because of that, the needs of members are usually not being addressed as much as they need to be. You are still needed every step of the way, but the process of connecting members will be infinitely easier and more efficient. You will be able to create and complete campaigns within minutes, and the rest will be taken care of by us. "
    },
    {
        id: '9',
        expanded: false,
        question: 'Who process and distributes the funds?',
        answer: "We do! We take care of that entire process, and we do so very quickly, usually with 24 hours.  "
    },
    {
        id: '10',
        expanded: false,
        question: 'How are funds distributed?',
        answer: "When a campaign is completed, an email is automatically sent to the recipient, who chooses how they'd prefer to receive their funds (either via Paypal or a check is mailed out)."
    },
    {
        id: '11',
        expanded: false,
        question: "I need funds but I don't want the entire community to know it's for me. What do I do?",
        answer: "Recipients of the funds are ALWAYS kept anonymous. The community admin will be the only members who will be privy to the persons identity. (This allows the recipient to walk around holding their head high, as no other community members are aware of their financial situation.)"
    },
    {
        id: '12',
        expanded: false,
        question: "How are members notified when there's an active campaign?",
        answer: "Each member of the group (and only this group) will receive a private text or email (whichever method they chose). They will also receive progress notifications - which members can modify to whichever messages they'd like to recieve - as they campaigns proceed."
    },
    {
        id: '13',
        expanded: false,
        question: "Can I be a member of more than one group?",
        answer: "Absolutelty! You can join any and as many groups as you'd like."
    },
    {
        id: '14',
        expanded: false,
        question: "I have more questions, how can I reach ourcommunity?",
        answer: "Send us an email with any questions or feedback - we love honest feedback, it'll help us continue to improve! - to hello@ourcommunity.one"
    }

]

export const YOUTUBE_IDS = [
    {
        id: "Kwfp66raww8",
        title: "Create a new group as an admin?",
    },
    {
        id: "lH87uXqzS0c",
        title: "Add members to a group?",
    },
    {
        id: "pg59H_WxfA4",
        title: "Join a group as a user?",
    },
    {
        id: "-eIIVRkMg5g",
        title: "Select preferred notification method and frequency?",
    },
    {
        id: "c7opkNlowGQ",
        title: "Donate to our balance anytime?",
    },
    {
        id: "lNsAC4L5qwc",
        title: "View donation history?",
    },
    {
        id: "RiMoWaX9Q0w",
        title: "Contact your admin to request funds or suggest a campaign?",
    },
];

export const RECURRING_DATA = [
    { id: 1, value: 'Weekly' },
    { id: 2, value: 'Daily' },
    { id: 3, value: 'Monthly' },
]

export const COMMUNICATION_METHOD = [
    { id: 0, value: 'Email' },
    { id: 1, value: 'SMS (Text)' }
]

export const SUBSCRIPTION_LABELS = [
    { id: 0, title: 'Invitations to additional groups' },
    { id: 1, title: 'New campaign launched' },
    { id: 2, title: 'Progress message. 25%' },
    { id: 3, title: 'Progress message. 50%' },
    { id: 4, title: 'Progress message. 75%' },
    { id: 5, title: 'Progress message. 100%' },
]

export const STEPS_CONTENT_TEXT_PART_1 = `We simply act as the bridge between those in financial stress and those that WANT to help out. We will NEVER send you promotional emails. We will NEVER share your information. We need you to join so that you can be notified when someone within your group is in need, and you can receive a private text message or email. `

export const STEPS_CONTENT_TEXT_PART_2 = `That's the only reason. Simply so that the recipient can hold their head high and remain dignified. They will NOT see an active campaign being run for them on group chats etc. They simply request funds, explain their need to their admin, and shortly thereafter receive the funds.`

export const OUR_BALANCE_DESCRIPTION = 'Funds from Our Balance are distributed between campaigns or can be credited to one campaign at any time.';
export const OUR_BALANCE_DESCRIPTION_NOTE = 'Please note that Our Balance amount is the actual amount in your Bank already after credit card processing fees have been deducted.';
export const ADD_MEMBERS_DESCRIPTION = 'In order to proceed you can fill in the forms and add members manually, or copy invitation with the link below and share with community members for them to register';

export const VALIDATION_TEXT_REGISTER = 'We have sent a verification link to the email address you used to create the account.';
export const VALIDATION_TEXT_REGISTER_SECOND = 'To finish registration process, check your email and follow the verification link. Please note that it can go to the spam or junk folder.';

export const VALIDATION_TEXT_CHANGE_EMAIL = 'We have sent a verification link to the new email address.';
export const VALIDATION_TEXT_CHANGE_EMAIL_SECOND = 'To finish changing email process, check your email and follow the verification link. Please note that it can go to the spam or junk folder.';

export const PASSWORD_ERROR = 'at least 8 characters, 1 upper case, 1 lower case and numeric should be embedded';

export const DONATION_FEE = 'Cover the processing fees';

export const ACCOUNT_SETUP_ERROR = "You are not member of any approved community. Please create a new one or join an existing one.";

export const policyIntro = `At Lift Our Own Inc, we respect your privacy as a donor, 
supporter, and/or user of our website. We are strongly committed to keeping any personal
information we obtain from you or about you secure and to being transparent about the ways
in which we use it.`;

export const firstParagraph = [
    {
        id: 0, value: `At Lift Our Own Inc, we respect your privacy as a donor,
    supporter, and/or user of our website. We are strongly committed to keeping any personal 
    information we obtain from you or about you secure and to being transparent about the ways 
    in which we use it.`},
    {
        id: 1, value: `This Privacy Policy is intended to help you understand
    the types of information we gather and cookies we use when you use our website 
    (www.ourcmmunity.one ). It also describes our practices for protecting, using, 
    and sharing such information, and the choices you have regarding use.`},
    {
        id: 2, value: `Thank you for taking the time to understand more about
    ourcmmunity.one by viewing our website. If you have any questions or comments about our
    website or this Privacy Policy, you can email us at hello@ourcmmunity.one.`}
]

export const secondParagraph = [
    {
        id: 0, value: `Lift Our Own Inc is a US 501(c)(3) non-profit based
    in Pennsylvania. Our website is operated in the United States and as such the personal
    information we collect will be transferred to us in the United States, and there
    are aspects of this Privacy Policy that only apply when we are required to comply
    with specific laws e.g. the European data protection law, the General Data Protection
    Regulation (“GDPR”). If you are interacting with us from the EU, EU data protection law is
    likely to apply.`},
    {
        id: 1, value: `If you have any questions about how we use your
    personal information and how we comply with our responsibilities, please contact us
    as follows:`},
]

export const thirdParagraph = [
    {
        id: 0, value: `Our primary goals in collecting personal information
    from users of our website are to further our charitable purposes (for example by taking
    donations), to thank you for your support, and to share information about our work.
    For the purposes of the GDPR, ourcmmunity.one is a “controller” of your personal
    information collected through the website or otherwise provided to us (for example by
    you via email).`},
    {
        id: 1, value: `Personal information is collected (1) directly from you when you provide it to us; (2) from 
    third parties where you have given your permission for it to be shared with us and (3) indirectly
    as you navigate through the website.`},
    {
        id: 2, value: `
(1) When you make a donation or sign up to receive email updates, we will request personal 
information, such as your name, e-mail address, mailing address, phone number, 
as well as ask how you heard about ourcmmunity.one. We use the personal information 
you provide to process your donation and/or communicate with you about it. In addition, 
we may use this personal information to keep you updated about our work if you indicate 
(by giving consent) that you would like us to do so. You can choose to stop receiving 
marketing/fundraising information by following the unsubscribe instructions included 
in the materials you receive or by contacting us at hello@ourcmmunity.one.`},
    {
        id: 3, value: `
We may share personal information with third party service providers we work with to perform 
these functions on our behalf, such as processing credit card payments and sending postal mail 
and email. If you choose to share where you heard about ourcmmunity.one, we may share that 
information with specific third parties.`},
    {
        id: 4, value: `
In connection with seeking employment with ourcmmunity.one, you may decide to submit, through
our sites or otherwise, personal information (including your name, address, telephone number,
e-mail address and any other personally identifiable information requested on our online 
and offline forms, as well as an electronic or paper copy of your resume/CV). All information 
entered into any online or offline forms related to employment or volunteering is held in
confidence, and will be viewed only for human resources purposes by ourcmmunity.one and
third parties that assist us with certain functions. This information may be used to assess
your qualifications as an employee or volunteer for ourcmmunity.one, or to analyze general
patterns in applications for positions or hiring at ourcmmunity.one.`},
    {
        id: 5, value: `
(2) When you make a donation to us via a third party, they may provide us with details of your 
donation and, where you have given that third party permission, they may share with us other 
personal information such as your name, location, mailing address, and contact details.`},
    {
        id: 6, value: `
(3) When you use our website, we may collect information about you through tracking technologies
such as cookies and web beacons. This may include information about your browsing actions 
such as the frequency with which you visit various parts of the website. We use the 
information collected primarily to inform our efforts to provide an enhanced experience 
on the website. Please refer to our Cookie Notice below for more information.`},
    {
        id: 7, value: `
From time to time, we may also obtain information about you from third-party sources, 
such as public databases, social media platforms, or third-party data providers. 
We take steps to ensure that such third parties are legally or contractually permitted 
to disclose such information to us.
`}]

export const thirdParagraphOne = [
    {
        id: 0, value: `ourcmmunity.one will use your personal information 
    for the purposes set out in this Privacy Policy, including:`},
    {
        id: 1, value: `•	to provide you with the information or services
    you have requested, and communicate with you in general`},
    {
        id: 2, value: `•	to analyze, evaluate and improve our work, programmes, 
    services, activities or information`},
    { id: 3, value: `•	to provide updates on our work and request donations` },
    { id: 4, value: `•	to invite you to talks and events hosted by us` },
    { id: 5, value: `•	to thank donors for their support` },
    { id: 6, value: `•	to administer any financial transaction between us` },
    { id: 7, value: `•	to recruit employees and volunteers` },
    { id: 8, value: `•	to ensure we are not contacting people who have told us not to` },
    { id: 9, value: `•	to satisfy legal obligations which are binding on us` },
    { id: 10, value: `•	for research purposes` },
    { id: 11, value: `•	for the prevention of fraud or misuse of services` },
    { id: 12, value: `•	and for the establishment, defense or enforcement of legal claims.` },
]

export const fourthParagraph = [{
    id: 0, value: `ourcmmunity.one engages third party vendors to assist 
    us with such functions as hosting our donor personal information database, sending email, 
    modeling our data, and processing online and mail donations and credit card payments. 
    In addition, ourcmmunity.one consults advisors in making organizational decisions and 
    developing long term plans. These companies and advisors have access to donors' personal 
    information as needed to perform their functions. ourcmmunity.one requires that they keep 
    such personal information confidential and that they not use such information for purposes 
    other than the functions they are assisting us with.
    `}, {
    id: 1, value: `We do not sell, rent, or give personal donor 
    information to any other party not under the employ of, or in a direct advisory or 
    vendorship role to ourcmmunity.one. We may disclose to third parties aggregate statistics 
    regarding donations but these statistics do not include any personally identifying information.
    The information given includes total number of donors and total amount of donations for 
    specific periods of time.`}, {
    id: 2, value: `
    ourcmmunity.one operates globally, and as such personal information that we 
    collect may be stored and processed in and transferred between any of the countries 
    in which we operate, including to countries outside of the UK or the European Economic 
    Area (EEA), to enable the use of the personal information in accordance with this Privacy 
    Policy. Please note that certain countries outside of the UK and EEA have a 
    lower standard of protection for personal information, including lower security 
    protections and fewer rights for individuals. Where your personal information is 
    transferred, stored, and/or otherwise processed outside the UK or EEA in a country 
    which does not offer an equivalent standard of protection to the UK or EEA, and that 
    information would otherwise fall within the protection of relevant EU law, we will 
    take all reasonable steps necessary to ensure that the recipient implements appropriate 
    safeguards designed to protect your personal information. If you have any questions about 
    the transfer of your personal information, please contact us using the details at section 2.
    `},
]

export const fifthParagraph = [{
    id: 0, value: `Where the GDPR applies to our operations, we are required
    to rely on one or more lawful bases to collect and use your personal information. Where this 
    is the case, we rely on the following lawful bases:`}, {
    id: 1, value: `
    •	Consent: We may ask for your consent to use your personal information for certain purposes, 
    for example, by asking you to agree to receive email marketing from us. You always have the 
    right to withdraw your consent.`}, {
    id: 2, value: `
    •	Legitimate interests: We may process personal information on the basis that there is a 
    legitimate interest, either to us or to a third party, and the processing is reasonably
    necessary to further that interest. Where we process your personal information on this 
    basis, we do so after careful consideration whether the same objective could be achieved 
    through other means and whether you would expect us to process your personal information, 
    and whether you would consider it reasonable to do so (in other words, we check that our 
    use is fair, balanced and does not unduly impact your rights).`}, {
    id: 3, value: `
    Our legitimate interests, for example, include the pursuit of our charitable purposes, 
    and administration - so for instance we will rely on the legitimate interest ground to 
    communicate with you in most instances such as to process your donations.`}, {
    id: 4, value: `
    •	Contract: We may process your personal information where we have a contract with you, 
    in order to fulfill that contract, or to take steps at your request prior to entering 
    into one.`}, {
    id: 5, value: `
    •	Legal obligation: We may rely on this basis where the processing of your personal 
    information is necessary for us to comply with a legal obligation to which we are subject 
    (for example, reporting to tax authorities).`}
]

export const fifthParagraphOne = [
    {
        id: 0, value: `As a matter of policy we will not sell or share personal 
    information about you to third parties in ways different from what 
    is disclosed in this Privacy Policy. However, we may disclose your 
    personal information:`},
    { id: 1, value: `(1) to comply with law or regulation,` },
    { id: 2, value: `(2) to our professional advisors (e.g. lawyers), where necessary to protect our interests,` },
    { id: 3, value: `(3) to protect your safety or security (including fraud protection),` },
    {
        id: 4, value: `(4) to protect the security of our website and any property that belongs to us, 
    our personnel or other users, and/or`},
    {
        id: 5, value: `(5) in the event that we transfer or receive any business or 
    assets (in which case we will disclose personal information 
    to the prospective transferor or transferee) or if substantially 
    all of our assets are acquired by a third party (in which case personal 
    information held by us may be one of the transferred assets), as part 
    of a restructure or otherwise.`},
    {
        id: 6, value: `Otherwise we will generally inform you and may ask for 
    your consent before we share your personal information with a 
    third party.` },
]

export const sixthParagraph = {
    id: 0, value: `All personal information is stored securely. 
    We endeavor to protect your personal information and employ both appropriate technical 
    and procedural methods, such as commercially reasonable administrative, technical, 
    and physical safeguards against accidental or unlawful destruction or loss, or unauthorized 
    disclosure, access or use. Please be aware that, despite our best efforts, no security measures 
    are perfect or impenetrable and any transmission of personal information is at your own risk.`
}

export const seventhParagraph = [{
    id: 0, value: `Personal information that we process shall not be kept 
    for longer than is necessary in connection with the purposes for which it was collected 
    and/or is used.`}, {
    id: 1, value: `
In some cases, we may keep your personal information for longer, for instance where we are 
required to do so in accordance with legal or regulatory requirements (such as tax and 
accounting).`}, {
    id: 2, value: `
In specific circumstances we may also retain your personal information for 
longer so that we have an accurate record of your dealings with us in the event 
of any complaints or challenges.
`},
]

export const eighthParagraph = [{
    id: 0, value: `In general, please note that we will honor your requests 
to exercise your rights to the extent possible and required under applicable law. Certain 
of these rights may only be available to you if you are located within the EU when you 
access our website or otherwise engage with us.
`}, {
    id: 1, value: `You can send us an email at hello@ourcmmunity.one  
to request access to, correct or delete your personal information, or fulfill 
any of the rights below. We may not accommodate a request if we cannot confirm 
your identity, or we can rely on exemptions - for instance we may not change 
personal information if we believe the change would violate any law, regulation, 
legal requirement or applicable policy or cause the information to be incorrect. 
Specifically, under the GDPR, your rights in relation to the personal information 
we hold are expressed as follows. You also have the right;`}, {
    id: 2, value: `
•	Of access to the personal information we hold 
`}, {
    id: 3, value: `•	To rectification of any 
personal information we hold
`}, {
    id: 4, value: `•	To erasure of your personal information`
}, {
    id: 5, value: `•	To restrict processing of your personal information`
}, {
    id: 6, value: `•	To data portability of your personal information`
}, {
    id: 7, value: `•	To object to processing; and`
}, {
    id: 8, value: `•	To not be subject to automated decision-making 
including profiling`}, {
    id: 9, value: `Please note that you also have the right to lodge a 
complaint with your local data protection authority about how we use your personal 
information if you are located in the UK or the EU. Please always consider raising 
your concern with us first by contacting us using the contact details in section 2.
`}
]

export const ninthParagraph = [
    {
        id: 0, value: `Individuals can find out if we hold any of their personal 
    information by making a “subject access request” under the GDPR.
    If we do hold personal information about you, we will
    (subject to entitlement and exemptions):`},
    { id: 1, value: `•	Give you a description of it;` },
    { id: 2, value: `•	Tell you why we are holding it;` },
    { id: 3, value: `•	Tell you why we are holding it;` },
    { id: 4, value: `•	Tell you who it could be disclosed to;` },
    {
        id: 5, value: `•	and Let you have a copy of the information 
    in an intelligible form`},
    {
        id: 6, value: `You may request a copy of the personal information 
    by emailing us at hello@ourcmmunity.one.`},
]

//terms

export const termsIntro = [{
    id: 0, value: `IMPORTANT - THIS IS A LEGAL AGREEMENT BETWEEN YOU AND LIFT OUR OWN INC  
    (“OURCOMMUNITY” OR “WE” OR “US” OR “OUR”). THE WEBSITE LOCATED AT WWW.OURCOMMUNITY.ONE  
    (THE “WEBSITE”), IS OWNED AND OPERATED BY LIFT OUR OWN INC . BEFORE ACCESSING OR USING 
    ANY PART OF THE WEBSITE, YOU SHOULD READ CAREFULLY THE FOLLOWING TERMS AND CONDITIONS 
    CONTAINED IN THIS TERMS OF USE AGREEMENT. YOUR ACCESS TO AND USE OF THE WEBSITE ARE 
    SUBJECT TO THESE TERMS OF USE, AS WELL AS TO ALL APPLICABLE LAWS AND REGULATIONS,
    AND ALONG WITH ANY OTHER TERMS AND CONDITIONS AS MAY BE SET FORTH BY OURCOMMUNITY.ONE.`}, {
    id: 1, value: `
    OURCOMMUNITY.ONE IS WILLING TO LICENSE AND ALLOW THE USE OF THIS WEBSITE
    ONLY ON THE CONDITION THAT YOU ACCEPT AND AGREE TO ALL OF THE TERMS AND
    CONDITIONS CONTAINED IN THESE TERMS OF USE. IF YOU DO NOT AGREE WITH THESE TERMS
    OF USE, YOU ARE NOT GRANTED PERMISSION TO ACCESS OR OTHERWISE USE THE WEBSITE. 
    YOUR ACCESS TO AND USE OF THIS WEBSITE CONSTITUTES YOUR ACCEPTANCE OF AND AGREEMENT 
    TO ABIDE BY THESE TERMS OF USE. THESE TERMS OF USE MAY BE MODIFIED BY OURCOMMUNITY.ONE
    FROM TIME TO TIME WITHOUT ADVANCE NOTICE BY POSTING HERE AND YOU WILL BE BOUND BY ANY
    SUCH MODIFIED TERMS OF USE IF YOU CONTINUE TO USE THIS WEBSITE AFTER SUCH CHANGES ARE
    POSTED. THE DATE THESE TERMS OF USE WERE LAST UPDATED IS STATED AT THE END OF THIS DOCUMENT.
    YOU ARE ENCOURAGED TO REVIEW THESE TERMS OF USE PERIODICALLY FOR UPDATES AND CHANGES.
    `
}]

export const description = `Ourcommunity.one. is a nonprofit 
that lets individual donors send money to those within their own 
circles who may be struggling financially.  The Website provides users 
with access to a variety of information and resources, including research 
papers, works of authorship, e-newsletters, news feeds, blogs, fundraising 
campaigns and other relevant content (collectively, the “Content”).`

export const persenalInformation = `You can visit the Website and access most
of the Content, features and functionality without being required to provide
us with any personal information. However, to gain access to certain Content
and some activities will require that you provide us with some personally
identifying information about yourself, as more specifically detailed in our privacy policy.`

export const ageRequirements = [{
    id: 0, value: `Ourcommunity.one Website is intended solely for users who are 13 years of age
    or older, and any registration by, use of or access to the Website by anyone under
    13 is unauthorized.  If you are 13 or older but under the age of 18, you should review
    these Terms of Use with your parent or guardian to make sure that your parent or
    guardian understands them, agrees to be bound by them and you should only use the
    Website with their permission and under their supervision.  Additionally,
    you have to be 18 years of age or older to make a donation.  If you are a
    parent or guardian of a child who is at least 13 years of age, but under the age of
    18, and you give your child permission to use the Website, you hereby agree to the
    terms set forth in these Terms if Use on behalf of both yourself and your child.`}, {
    id: 1, value: `
    If you reside in a jurisdiction that would restrict the use of the Website - or any of 
    the functionalities or features offered via the Website - because of age, or restricts 
    the ability to enter into contracts such as this one due to age, you must abide by such 
    age limits and you must not use the Website if you are not permitted to do so by such 
    local jurisdiction.   Please note, if  you reside in the European Economic Area, 
    Switzerland or United Kingdom, you must be 16 years of age or older, and any use of 
    or access to the Website by anyone under 16 is unauthorized. 
    `
}]

export const trademark = `Certain trademarks are the service marks and trademarks 
of ourcommunity.one. All page headers, custom graphics, and button 
icons are service marks, trademarks, logos, and/or trade dress of 
ourcommunity.one. All other trademarks, service marks, trade dress, 
product names, company names or logos, whether registered or not, on the Website 
are the property of their respective owners. In addition to complying with all applicable 
laws, you agree that you will not use any such trademarks, service marks, trade dress,
or other logos from these Website without the prior written authorization of ourcommunity.one
or their respective owners.`

export const copyright = `Except as otherwise expressly stated, all Content appearing 
on the Website - including all scripts, videos and similar materials, is the copyrighted 
work of either ourcommunity.one or third party partners and suppliers, and is protected 
by U.S. and international copyright laws. The compilation (meaning the collection, 
arrangement and assembly) of all Content is also the exclusive property of ourcommunity.one,
and is protected by U.S., and international copyright laws. Except as otherwise expressly 
stated herein or as expressly permitted, you may not alter, modify, copy, distribute 
(for compensation or otherwise), transmit, display, perform, reproduce, reuse, post, 
publish, license, frame, download, store for subsequent use, create derivative works 
from, transfer, or sell any information or Content obtained from the Website, in whole 
or in part, including any text, images, audio, and video in any manner, without the 
prior written authorization of Ourcommunity.one, or any applicable third-party suppliers 
or authorized users. The use of Content, including images, by you, or anyone else 
authorized by you, is prohibited unless specifically permitted by ourcommunity.one. 
Any unauthorized use of text or images may violate copyright laws, trademark laws, 
the laws of privacy and publicity, and applicable regulations and statutes. 
Ourcommunity.one does not warrant nor represent that your use of any Content or 
materials displayed on the Website will not infringe rights of third parties. `

export const license = `These Terms of Use provide you a personal, revocable,
limited, non-exclusive, royalty-free, non-transferable license to access the
Website solely for your own personal use.  Your use of the Website, and access
to the Content, including any images, videos, materials or information made available
through the Website are conditioned on your continued compliance with the terms and conditions
of these Terms of Use.  Accordingly, you expressly acknowledge and agree that
ourcommunity.one transfers no ownership or intellectual property interest or title
in the Content. `

export const reservationRights = `Ourcommunity.one reserves the right, 
in its sole discretion and at any time, to modify, interrupt, limit, suspend or discontinue, 
temporarily or permanently, the Website, in whole or in part, including, as we deem necessary 
for purposes of maintenance, upgrades and the like, or to maintain the Website or to comply 
with applicable law. Ourcommunity.one shall not be liable to you or to any third party for 
any such modifications, suspensions or discontinuances of the Website.`

export const payments = `You can make an online donation to ourcommunity.one. 
    Ourcommunity.one has engaged the services of third party payment service provider(s) 
    to process all donation/payment transactions.  Ourcommunity.one is not affiliated with 
    any such third party payment service provider(s), and neither is the agent or employee 
    of the other, and ourcommunity.one is not responsible in any way for the actions or 
    performance (or lack thereof) of such third party payment service provider(s).  
    Accordingly, ourcommunity.one expressly disclaims responsibility and liability 
    for all payment processor services, and you hereby agree that ourcommunity.one
    shall not be responsible for any loss or damage of any sort incurred as a result 
    of such services. Additionally, since such third party payment service provider(s) 
    will have access to the information you provide to them when making a donation, you 
    should not make a donation if you are not comfortable with sharing your information 
    with these third parties. You hereby acknowledge and agree that, for all donations,
    (1) you will not use an invalid or unauthorized credit or debit card or other payment
    method; (2) all donations are final and non-refundable. If you have any questions
    regarding any donation, please contact us by email at  hello@ourcommunity.one.                   
    `

export const blogs = `The Website contains user generated content blogs. The content,
views and opinions published in blogs and videos may be written by employees, volunteers
or unaffiliated third-party contributors.  All blogs and videos belong solely to their
respective authors and do not necessarily reflect the views of ourcommunity.one, its
management or employees. Ourcommunity.one is not responsible for, and disclaims any and
all liability for the content of the blogs and videos. `

export const acknowledgment = `You hereby agree to abide by all applicable local, state, 
national, and international laws and regulations with respect to your use of ourcommunity.one 
Website and all Content. In addition, you also acknowledge and agree that use of the Internet
and access to or transmissions or communications with ourcommunity.one Website is solely at
your own risk.  While ourcommunity.one has endeavored to create a secure and reliable Website,
you should understand that the confidentiality of any communication or material transmitted
to/from ourcommunity.one Website over the Internet or other form of global communication 
network cannot be guaranteed.  Accordingly, ourcommunity.one is not responsible for the
security of any information transmitted to or from the Website. `

export const prohibitedIntro = `In addition to any other restrictions or conditions of use, you hereby acknowledge,
    and represent and warrant, that your use of the Website, will not be, or alleged to be 
    unlawful, unauthorized, fraudulent or malicious in purpose.  In addition, you agree you 
    will not:`

export const prohibited = [{
    id: 1, value: `1.	license, sublicense, sell, resell, transfer, assign, distribute or 
    otherwise commercially exploit or make available to any third party the Website 
    or Content or in any way;
    `}, {
    id: 2, value: `2.	modify or make derivative works based upon the Website or Content;
    `}, {
    id: 3, value: `3.	create Internet “links” to the Service or “frame” or “mirror”  
    the Website on any other server or wireless or Internet-based device;
   `}, {
    id: 4, value: ` 4.	reverse engineer or access the Website in order to (a) design or 
    build a competitive product or service, (b) design or build a product using similar 
    ideas, features, functions or graphics of the Services or Application, or (c) copy 
    any ideas, features, functions or graphics of the Services or Application;
    `}, {
    id: 5, value: `5.	launch an automated program or script, including, but not limited to, 
    web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, 
    or any program which may make multiple server requests per second, or unduly burdens or 
    hinders the operation and/or performance of the Services or Website;
    `}, {
    id: 6, value: `6.	interfere with or disrupt the integrity or performance of the Website or 
    the data contained therein, or attempt to gain unauthorized access to the Website or 
    its related systems or networks.
    `}, {
    id: 7, value: `Ourcommunity.one will have the right to investigate and prosecute violations 
    of any of the above to the fullest extent of the law. You acknowledge that ourcommunity.one 
    has no obligation to monitor your access to or use of the Website, but has the right to 
    do so for the purpose of operating the Website and providing the Services, to ensure your 
    compliance with these Terms of Use, or to comply with applicable law or the order or 
    requirement of a court, administrative agency or other governmental body.
    `
}]

export const thirdPartyLinks = `The Website contains hyperlinks to other websites - including a 
variety of social media sites such as Facebook, Twitter, Instagram, YouTube and LinkedIn - each 
owned and operated by parties other than ourcommunity.one. We do not control the websites 
accessed from such hyperlinks, and cannot be held responsible for the content or accuracy 
of anything on these sites, including any advertisements, products for sale, or services.  
Any such websites are likely to set forth specific terms of use and privacy policies that 
you should review.  Ourcommunity.one shall not be responsible or liable, directly or 
indirectly, for any damages or losses caused or alleged to be caused by or in connection 
with the use of or reliance on such content, articles, resources, advertisements, products, 
services or other materials available on or through any such linked website.  
Ourcommunity.one is not responsible for the privacy practices of any other website, 
and we encourage you to read the privacy policy of each website you visit prior to 
providing them any information or undertaking any activities.`

export const compliance = [{
    id: 0, value: `Ourcommunity.one Website is hosted in the United States.  
    If you are a non-U.S. user of ourcommunity.one, by visiting the Website you agree to 
    comply with all applicable federal and state U.S. laws. You further agree to comply with 
    all applicable laws regarding the transmission of data exported from the United States 
    or the country in which you reside. `}, {
    id: 1, value: `We make no representations that the Website and any and all information 
    contained therein, including any Content, is appropriate, available or legal 
    in any particular location. The Website is not intended for access or use by 
    any person or entity in any jurisdiction or country where such distribution or 
    use would be contrary to law or regulation or which would subject ourcommunity.one to 
    any registration requirement within such jurisdiction or country. You hereby acknowledge 
    and agree that you are choosing to access the Website and all related services, 
    information or Content on your own initiative and that you are responsible for 
    compliance with all applicable international, federal, state, local and any other 
    applicable laws, statutes, ordinances and regulations regarding your use of the thereof.
    `
}]

export const disclaimer = [{
    id: 0, value: `The Content contained on this Website has been prepared by ourcommunity.one as a 
    convenience to its donors, users, and Website visitors.  Ourcommunity.one has used 
    reasonable efforts in collecting, preparing and providing such Content, but makes no 
    warranty or guarantee about the accuracy, completeness, or adequacy of the Content 
    contained in or linked to this Website or any other Website maintained 
    by ourcommunity.one.`}, {
    id: 1, value: ` 
    YOUR USE OF THIS WEBSITE IS AT YOUR SOLE RISK. ALL CONTENT IS PROVIDED ON AN “AS IS” OR 
    “AS AVAILABLE” BASIS, AND OURCOMMUNITY.ONE EXPRESSLY DISCLAIMS ALL WARRANTIES AND 
    CONDITIONS WITH RESPECT TO THE WEBSITE AND ALL ELEMENTS THEREOF, WHETHER IMPLIED, 
    EXPRESS, OR STATUTORY, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS 
    FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT OF THIRD-PARTY RIGHTS, SATISFACTORY 
    QUALITY, QUIET ENJOYMENT AND ACCURACY. OURCOMMUNITY.ONE MAKES NO WARRANTY THAT THE 
    WEBSITE AND CONTENT IS ACCURATE, TIMELY, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE, OR 
    THAT ANY SUCH PROBLEMS WILL BE CORRECTED.
    `
}]

export const liability = [{
    id: 0, value: `IN ADDITION TO THE ABOVE WARRANTY DISCLAIMERS, YOU UNDERSTAND AND AGREE THAT 
    IN NO EVENT WILL OURCOMMUNITY.ONE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, 
    SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF USE,
    LOSS OF INCOME, LOSS OF GOODWILL OR REPUTATION, DAMAGE TO OR CORRUPTION OF DATA, OR
    PERSONAL INJURY, WHETHER IN AN ACTION IN CONTRACT, TORT, OR OTHERWISE, ARISING OUT OF
    OR IN ANY WAY CONNECTED WITH USE OF THE WEBSITE (OR INABILITY TO USE THE WEBSITE), AND
    YOUR USE OF OUR CONTENT.  YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE IS
    PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE OURCOMMUNITY.ONE AND ITS AFFILIATES
    DIRECTLY OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING
    FROM YOUR USE OF THE WEBSITE.`}, {
    id: 1, value: `
    CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION 
    OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF 
    THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND 
    YOU MIGHT HAVE ADDITIONAL RIGHTS.
    `
}]

export const indemnity = `You agree to defend, indemnify, and hold harmless ourcommunity.one 
and affiliates and all of their respective employees, funders, parents, subsidiaries, 
joint ventures, affiliates, agents, developers, directors, officers and attorneys from and 
against any and all claims, proceedings, damages, injuries, liabilities, losses, costs, and 
expenses (including reasonable attorneys' fees and litigation expenses) relating to or arising 
from i) any breach or alleged breach by you of this Terms of Use, or ii) any act or 
omission related to your use of this Website or Content.`

export const arbitration = `At ourcommunity.one's sole discretion, any disputes or 
claims under this Terms of Use or its breach may be submitted to and resolved exclusively 
by arbitration conducted in accordance with American Arbitration Association rules.  
One arbitrator appointed under such rules shall conduct arbitration.  Any such arbitration 
shall be in Luzerne  County, Virginia, and the laws of Pennsylvania shall be applied.  
Any decision in arbitration shall be final and binding upon the parties. Judgment may be 
entered thereon in any court of competent jurisdiction. Notwithstanding the above, 
ourcommunity.one may sue in any court for infringement of its proprietary or intellectual 
property rights. All claims you bring against ourcommunity.one must be resolved in accordance 
with this section. All claims filed or brought contrary to this section shall be considered 
improperly filed. Any claim or cause of action arising out of or related to use of the Website 
and/or any of the Content, or these Terms of Use, must be filed within one (1) year after such 
claim or cause of action arose regardless of any status or law to the contrary. In the event 
any such claim or cause of action is not filed within such one (1) year period, such claim or 
cause of action shall be barred. Any failure to act by ourcommunity.one with respect to a 
breach by you or others does not waive ourcommunity.one's right to act with respect to 
subsequent or similar breaches.`

export const termination = `This Terms of Use and your right to use ourcommunity.one 
Website will take effect at the moment you access the Website, and is effective, binding 
and enforceable for as long as use the Website.  In addition, ourcommunity.one reserves all
of its legal rights to pursue any and all legal remedies if we believe you are using the 
Website for fraudulent or unlawful activity or you are taking any actions or omissions that 
violate any term or condition of this Terms of Use, or in order to protect its name and 
goodwill, its business, and/or other users.  All provisions of this Terms of Use that by 
their nature our intended to survive termination will so survive and remain binding and 
effective - such provisions include, but are not limited to the miscellaneous section 
below, the provisions concerning ourcommunity.one's proprietary rights, indemnity, 
disclaimers of warranty, limitation of liability, and governing law.`


export const miscellaneous = [{
    id: 0, value: `This Terms of Use constitutes the entire agreement between 
you and ourcommunity.one and governs your use of the Website, superseding any prior 
agreements between you and ourcommunity.one. You will not assign the Terms of Use or 
assign any rights or delegate any obligations hereunder, in whole or in part, whether 
voluntarily or by operation of law, without the prior written consent of ourcommunity.one. 
Any purported assignment or delegation by you without the appropriate prior written consent
of ourcommunity.one will be null and void. We may assign these Terms of Use or any 
rights hereunder without your consent. Failure by ourcommunity.one to insist on strict 
performance of any of the terms and conditions of this Terms of Use will not operate as 
a waiver by ourcommunity.one of that or any subsequent default or failure of performance.  
The Website is not intended for distribution to or use by any person or entity in any 
jurisdiction or country where such distribution or use would be contrary to law or 
regulation or which would subject ourcommunity.one to any registration requirement 
within such jurisdiction or country. We reserve the right to limit the availability 
of the Website or any portion of the Website, to any person, geographic area, or jurisdiction, 
at any time and in our sole discretion, and to limit the service or other features that the 
Website provides.  If any provision (or part thereof) contained in this Terms of Use is 
determined to be void, invalid, or otherwise unenforceable by a court of competent 
jurisdiction or on account of a conflict with an applicable government regulation, 
such determination shall not affect the remaining provisions (or parts thereof) 
contained herein and the illegal, invalid, or unenforceable clause shall be modified 
in compliance with applicable law in a manner that most closely matches the intent of 
the original language.  No joint venture, partnership, employment, or agency relationship 
exists between you and ourcommunity.one as result of this Terms of Use or your utilization 
of the Website.  Headings herein are for convenience only.`}, {
    id: 1, value: `Terms of Use: last updated January 1, 2022. `
}]