import React, { useState, useEffect } from "react";
import './campaign.scss';
import ReactGA from 'react-ga4';

import Header from "../../../components/Header/header";
import { useHistory, useParams } from "react-router";
import Title from "../../../uiKit/Title/Title";
import { gray600 } from "../../../uiKit/assets/colors/colors";
import CustomInput from "../../../uiKit/Input/CustomInput";
import CustomCheckbox from "../../../uiKit/CustomCheckbox/customCheckbox";
import CustomButton from "../../../uiKit/Button/CustomButton";
import { onlyNumbers } from "../../../helpers/validation";
import { getPaymentSettings, getSavedCard } from "../../../redux/state/paymentSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DONATION_FEE, FUND_INFO } from "../../../helpers/constants";

const DonationAmount = () => {

    const isLoading = useSelector(state => state.payment.isLoading.campaignPaymentIntent, shallowEqual);

    const history = useHistory();
    const dispatch = useDispatch();

    const { campaignId, communityId } = useParams();

    const email = useSelector(state => state.account.userDetails.email, shallowEqual);
    const firstName = useSelector(state => state.account.firstName, shallowEqual);
    const lastName = useSelector(state => state.account.lastName, shallowEqual);

    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');

    const [coverFees, setCoverFees] = useState(true);
    const [savePaymentMethod, setSavePaymentMethod] = useState(true);
    const [isAnonymous, setIsAnonymous] = useState(false);

    const onChangeHandler = (e) => {
        const value = e.target.value;
        if (onlyNumbers(value)) {
            if (value === '0') {
                setError('Insufficient amount');
                setAmount('');
            } else {
                setError('');
                setAmount(value);
            }
        } else {
            setError('Only numbers allowed');
        }
    }

    const onPayClick = () => {
        ReactGA.event('donate_campaign_enter_amount');
        const body = {
            incomingBalanceId: communityId,
            amount: parseInt(amount),
            isAnonymous,
            userInfo: {
                firstName,
                lastName,
                email
            },
            savePaymentMethod,
            coverFees,
            campaignId
        }
        history.push({
            state: { body, balanceType: 1 },
            pathname: '/campaigns/checkout'
        })
    }

    useEffect(() => {
        dispatch(getPaymentSettings())
            .then(unwrapResult)
            .then(response => setIsAnonymous(response))
            .catch(({ title }) => console.error(title))
    }, [dispatch])

    useEffect(() => {
        dispatch(getSavedCard());
    }, [dispatch])

    return (
        <>
            <Header title='Campaign page' onClick={() => history.push(`/campaigns/campaign/${campaignId}`)} />
            <div className='boxShadow donationWrapper'>
                <Title
                    text='Donation amount:'
                    fontSize={32}
                    lineHeight={40}
                    style={{ marginBottom: '16px' }}
                />
                <CustomInput
                    value={amount}
                    onChange={onChangeHandler}
                    error={!!error}
                    errorMessage={error}
                    placeholder='0'
                />
                <CustomCheckbox
                    label={DONATION_FEE}
                    checked={coverFees}
                    onChange={() => setCoverFees(!coverFees)}
                    style={{ marginBottom: '16px' }}
                />
                <CustomCheckbox
                    label='Please save my credit card for future payments'
                    checked={savePaymentMethod}
                    onChange={() => setSavePaymentMethod(!savePaymentMethod)}
                    style={{ marginBottom: '16px' }}
                />
                <CustomCheckbox
                    checked={isAnonymous}
                    onChange={() => setIsAnonymous(!isAnonymous)}
                    label='Donate Anonymously'
                    style={{ marginBottom: '48px' }}
                />
                <CustomButton
                    title='Donate'
                    style={{ marginBottom: '16px' }}
                    disabled={!!error || !amount.length || isLoading}
                    loading={isLoading}
                    onClick={onPayClick}
                />
                <Title
                    text="You'll be asked for your payment info on the next page."
                    fontSize={12}
                    lineHeight={14}
                    fontWeight={400}
                    color={gray600}
                />
                <Title
                    text={FUND_INFO}
                    overflowText={false}
                    fontSize={12}
                    lineHeight={14}
                    fontWeight={400}
                    style={{
                        marginTop: "16px",
                    }}
                    color={gray600}
                />
            </div>

        </>
    )
}

export default DonationAmount;